import React from 'react'
import { InputTemp } from '../../../utils/JsxTemplate'

export default function Pwd() {
  return (
    <>
      <InputTemp inputType='password' name='password' placeHolder='Password'  />
      
    </>
  )
}
