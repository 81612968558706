import React from 'react'
import { InputTemp } from '../../../utils/JsxTemplate'

export default function Lname() {
  return (
    <>
      <InputTemp inputType='text'  name='lname' placeHolder='LastName'  />
    
    </>
  )
}
