import React from 'react'
import { InputTemp } from '../../../utils/JsxTemplate'

export default function Fname() {
  return (
    <>
      <InputTemp inputType='text' name='fname' placeHolder='FirstName'  />
    </>
  )
}
