import React from 'react'
import { InputTemp } from '../../../utils/JsxTemplate'

export default function Phone() {
  return (
    <>
      <InputTemp inputType='number' name='phone' placeHolder='Phone Number'  />
      
    </>
  )
}
