import React from 'react'
import { InputTemp } from '../../../utils/JsxTemplate'

export default function Email() {
  return (
    <>
      <InputTemp inputType='text' name='email' placeHolder='Email'  />
      
    </>
  )
}
