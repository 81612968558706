
import { 
    user_route 
} from './routes/user_route';


const rolePermission = {
    user: user_route 
}


export default rolePermission;